<template>
  <div class="kits-ethnicity">
    <h4>Kits</h4>

    <div class="kits-ethnicity-body">
      <div v-for="(kitItem, index) in orderKits" :key="index">
        <div class="ethnicity-item">
          <h5>{{kitItem.personName}}</h5>
          <div class="lab-dropdown">
            <div class="lab-dropdown-btn" @click="kitItem.isShowDropdown = !kitItem.isShowDropdown" ref="dropdownRegionGrup">
              <span class="lab-dropdown-btn-value">{{ truncatedEthnicity(kitItem.selectedRegionName) }}</span>
              <span class="lab-dropdown-btn-icon">
                <img :src="'https://image.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/dropdown.svg'">
              </span>
            </div>
            <small class="text-danger" v-show="kitItem.isEmptyPresetEthnicityId">select ethnicity is required</small>
            <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': kitItem.isShowDropdown }">
              <ul>
                <li v-for="ethnicityItem in ethnicityList" 
                  :key="ethnicityItem.id" 
                  @click="onSelectEthnicity(kitItem, ethnicityItem.id, ethnicityItem.ethnicName)">
                  {{ ethnicityItem.ethnicName }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr v-if="index !== orderKits.length - 1"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/common/api.service';

export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      isShowDropdown: false,
      ethnicityList: [],
    }
  },
  methods: {    
    documentClick(e) {
      /* eslint-disable */
      try {
        if (!event.target.closest('.lab-dropdown-btn')) {
          this.$store.commit('g25PartnerCheckout/closeDropdowns');
        }
      } catch (error) {}
    },
    truncatedEthnicity(ethnicity) {
      if (ethnicity.length > 20) {
        return ethnicity.substring(0, 20) + '...'; 
      } else {
        return ethnicity;
      }
    },

    onLoadEthnicities() {
      ApiService.get('Ethnicity', query, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.ethnicityList = response.result;
          } else {
            AlertService.error(this._vm, response.message)
          }
        });
    },
    onSelectEthnicity(kitItem, id, title) {
      kitItem.presetEthnicityId = id;
      kitItem.selectedRegionName = title;
      kitItem.isEmptyPresetEthnicityId = false;
    },
  },
  computed: {
    orderKits() {
      return this.$store.getters['g25PartnerCheckout/getCreateOrderData'].kits;
    },
  },
  mounted() {
    this.onLoadEthnicities();
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.kits-ethnicity {
  padding: 1rem 1.5rem;
  
  .kits-ethnicity-body {
    padding: 1rem;

    hr {
      border-color: var(--lab-carbon-fiber) !important;
    }
  }
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .ethnicity-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-size: 0.9rem;
    }
  }
}

.lab-dropdown {
  .lab-dropdown-btn {
    padding: 0.3rem 0.3rem;
    font-size: 0.9rem;
    svg {
      fill: var(--lab-carbon-fiber) !important;
    }
  }
}

</style>
